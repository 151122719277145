body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  font-family: "DM Sans", sans-serif;
  -webkit-appearance: button;
}

/* Remove estilos padrão para todos os inputs */
input,
select,
textarea {
  outline: none;
  appearance: none;
  border: none;
  border-radius: 0;
  padding: 0px;
  margin: 0px;
  font-family: inherit;
  font-size: inherit;
}

fieldset {
  border: none;
  margin: 0px;
  padding: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0px;
  padding: 0px;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.material-symbols-outlined {
  font-variation-settings:
    "FILL" 0,
    "wght" 400,
    "GRAD" 0,
    "opsz" 24;
}
